<template>
  <div>
    <v-btn
      v-if="$admin.can('roles.create')"
      color="primary"
      dark
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn>
    <v-dialog
      v-if="isVisibleDialog"
      ref="roleModal"
      v-model="isVisibleDialog"
      scrollable
      persistent
      max-width="800px"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('roles.create')"
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template> -->
      <v-form
        ref="form"
        v-model="is_valid"
        lazy-validation
      >
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("role") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="role.type"
                        outlined
                        :disabled="!role.canEdit && role.canEdit !== undefined"
                        dense
                        :items="productOptions"
                        item-value="id"
                        item-text="name"
                        :label="$t('user_type_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      v-if="role.type == 'product'"
                      cols="6"
                    >
                      <v-select
                        v-model="role.name"
                        outlined
                        :disabled="!role.canEdit && role.canEdit !== undefined"
                        dense
                        :items="nameOptions"
                        item-value="id"
                        item-text="name"
                        :label="$t('name_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      v-else
                      cols="6"
                    >
                      <v-text-field
                        v-model="role.name"
                        dense
                        outlined
                        :disabled="!role.canEdit && role.canEdit !== undefined"
                        :label="$t('name_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>

                    <v-col
                      v-if="role.type == 'product'"
                      cols="6"
                    >
                      <v-select
                        v-model="role.product_tier_id"
                        outlined
                        :disabled="!role.canEdit && role.canEdit !== undefined"
                        dense
                        :items="tiersOptions"
                        item-value="id"
                        item-text="product_tier_name"
                        :label="$t('product_tiers_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      v-if="isOemType"
                      cols="6"
                    >
                      <v-autocomplete
                        v-model="role.tenant_id"
                        dense
                        :items="oemClinetList"
                        item-value="id"
                        item-text="name"
                        :label="$t('select_tenant')"
                      />
                    </v-col>
                  </v-row>

                  <v-col cols="12">
                    {{ $t("permissions") }}
                  </v-col>

                  <v-container
                    v-for="(group, key) in ['orko', 'oem'].includes(role.type)
                      ? permissions
                      : role.type == 'product'
                        ? role.product_tier_id
                          ? permissions
                          : []
                        : []"
                    :key="key"
                  >
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="module"
                              dense
                              outlined
                              hide-details
                              :ripple="false"
                              multiple
                              :label="key"
                              :value="key"
                              @change="updateRolePermissions(key, role)"
                            />
                          </v-col>

                          <v-col
                            v-for="permission in group"
                            :key="permission.id"
                            cols="3"
                          >
                            <v-checkbox
                              v-model="role.permission_ids"
                              dense
                              outlined
                              hide-details
                              :ripple="false"
                              :label="permission.name"
                              :value="permission.id"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="!is_valid"
              :loading="is_loading_save"
              @click="saveForm(role)"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      module: [],
      is_valid: true,
      is_loading_save: false,
      role: {
        name: null,
        permission_ids: [],
        type: null,
        product_tier_id: null,
      },
      isVisibleDialog: false,
      productOptions: [
        // { id: "oem", name: "OEM" },
        { id: "orko", name: "ORKO" },
        { id: "product", name: "Product" },
      ],
      nameOptions: [
        { id: "admin", name: "Admin" },
        { id: "staff", name: "Staff" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      roles: "roles/listRoles",
      permissions: "permissions/listPermissions",
      oemClinetList: "oemclients/getOemsList",
      tiersOptions: "permissions/getTiersOptions",
    }),
    isOemType() {
      return this.role.type == "oem";
    },
  },
  watch: {
    "role.type": {
      handler: function (val) {
        this.role.name = null;
        this.role.permission_ids = [];
        this.role.product_tier_id = null;
        if (val == "product") {
          this.$store.dispatch("permissions/tiersOptions");
        }
      },
      deep: true,
    },
    "role.product_tier_id": {
      handler: async function (val) {
        this.role.permission_ids = [];
        if (val) {
          await this.$store.dispatch("permissions/tiersPermissionList", {
            product_tier_id: val,
          });
        } else {
          await this.$store.dispatch("permissions/list");
        }
      },
    },
  },
  methods: {
    openModal() {
      this.isVisibleDialog = true;
    },
    closeForm() {
      this.isVisibleDialog = false;
      this.$refs.form.reset();
      this.is_loading_save = false;
    },

    async saveForm(role) {
      if (this.$refs.form.validate()) {
        try {
          this.is_loading_save = true;
          Object.keys(role).forEach((key) => {
            if (role[key] === "" || role[key] == null) {
              delete role[key];
            }
          });
          const params = { role, filter: { ...this.filterOptions } };
          await this.$store.dispatch("roles/add", params);
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
        this.is_loading_save = false;
      }
    },

    updateRolePermissions(current_group, role) {
      const permission_ids = this.permissions[current_group].map((item) => {
        return item.id;
      });

      if (role.permission_ids !== undefined) {
        if (this.module.includes(current_group)) {
          permission_ids.forEach(function (id) {
            if (role.permission_ids.indexOf(id) == -1)
              role.permission_ids.push(id);
          });
        } else {
          permission_ids.forEach(function (id) {
            role.permission_ids = role.permission_ids.filter(function (item) {
              return item !== id;
            });
          });
        }
      }
    },
  },
};
</script>